import { Spin } from "antd";
import { useContext } from "react";
import {
  Outlet,
  Routes,
  Route,
  NavLink,
  useSearchParams,
} from "react-router-dom";

import { AccountSettings } from "./AccountSettings";
import { CourseLibrary } from "./CourseLibrary";
import { Landing } from "./Landing";
import {
  TermsOfService,
  PrivacyPolicy,
} from "./LegalDisclaimers";
import { LoginContext } from "./LoginContext";


function MainPage(props) {
  const [searchParams,] = useSearchParams();
  const paramString = searchParams.toString();
  const loginContext = useContext(LoginContext);

  if (props.isLoading === true) {
    return (
      <div className="mainContent">
        <Spin size="large" fullscreen={true} delay={100} />
      </div>
    );
  }

  if (!loginContext.loggedIn) {
    return <></>;
  }

  const accountSettings = <AccountSettings />;
  const landing = <Landing role={props.activeRole} />;
  const courseLibrary = <CourseLibrary activeRole={props.activeRole} />;
  const termsOfService = <TermsOfService />;
  const privacyPolicy = <PrivacyPolicy />;

  const logoutButton = (
    <button
      onClick={
        async e => {
          e.preventDefault();
          await loginContext.logout();
        }
      }
    >
      Logout
    </button>
  );

  if (props.singlePage) {
    return (
      <div className="mainContent">
        {accountSettings}
        {landing}
        {courseLibrary}
        {termsOfService}
        {privacyPolicy}
        <div className="otherComponents">
          {logoutButton}
        </div>
      </div>
    );
  }

  return (
    <>
      <nav className="mainNav">
        <NavLink to={`/account-settings?${paramString}`}>
          Account Settings
        </NavLink>
        <NavLink to={`/landing?${paramString}`}>
          Portal
        </NavLink>
        <NavLink to={`/course-library?${paramString}`}>
          Course Library
        </NavLink>
        <NavLink to={`/terms-of-service?${paramString}`}>
          Terms of Service
        </NavLink>
        <NavLink to={`/privacy-policy?${paramString}`}>
          Privacy Policy
        </NavLink>
      </nav>
      <div className="otherComponents">
        {logoutButton}
      </div>
      <Outlet />
      <Routes>
        <Route path="account-settings" element={accountSettings} />
        <Route path="landing" element={landing} />
        <Route path="course-library/*" element={courseLibrary} />
        <Route path="terms-of-service" element={termsOfService} />
        <Route path="privacy-policy" element={privacyPolicy} />
      </Routes>
    </>
  );
}

export { MainPage };