import { useStorageUrl } from "./StorageUtils";

const imagesToLoad = {
  "eeiLogo": "eei-logo-nav-icon.png",
  "loginHomePagePhoto": "baim-hanif-pYWuOMhtc6k-unsplash-2.png"
};

function FirebaseImage(props) {
  const { imageid, hide } = props;
  const display = hide ? "none" : undefined;
  const imageName = imagesToLoad[imageid];
  const url = useStorageUrl(`public/images/${imageName}`);

  if (url === "") {
    return <div></div>;
  }
  return (
    <img style={{ display: display }} src={url} alt={props.alt} {...props} />
  );
}

export { FirebaseImage };