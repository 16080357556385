import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  useLocation,
} from "react-router-dom";

import { AdminToolbar } from './AdminToolbar';
import { ContactInfo } from './ContactInfo';
import { FirebaseImage } from './FirebaseImage';
import { FulfillmentPolicy } from './FulfillmentPolicy';
import { Home } from './Home';
import {
  LegalDisclaimers,
} from './LegalDisclaimers';
import { LoginContext, useLoginContext } from "./LoginContext";
import { MainPage } from "./MainPage";
import { MaybeRedirect } from "./MaybeRedirect";
import { SignUp } from "./SignUp";

import './App.css';


function App() {
  const [activeRole, setActiveRole] = useState("");
  const [singlePage, setSinglePage] = useState(false);
  const [firstLoadPaused, setFirstLoadPaused] = useState(true);
  const loginContext = useLoginContext();
  const location = useLocation();
  const { pathname: urlPath } = location;
  const atLoginFinish = urlPath === "/login/finish-email-signin"

  const isAdmin = (
    loginContext.loggedIn && loginContext.userRoles.includes("admin")
  );

  // wait half a second for the local state to load; if not, resume loading
  // the site in a not-logged-in context
  useEffect(() => {
    let ignore = false;

    if (!firstLoadPaused) {
      return;
    }

    setTimeout(() => {
      if (!ignore && !atLoginFinish) {
        setFirstLoadPaused(false);
      }
    }, 2000);

    return () => { ignore = true; };
  }, [firstLoadPaused, atLoginFinish]);

  useEffect(() => {
    const initialRole = (
      loginContext.userRoles.includes("admin") ? "admin"
        : loginContext.userRoles.length > 0 ? loginContext.userRoles[0]
          : ""
    );

    // If logged in, we can proceed, firebase has loaded
    if (loginContext.loggedIn) {
      setActiveRole(activeRole || initialRole);
      setFirstLoadPaused(false);
      return;
    }

    // If not logged in, and the first load it paused, not sure if it just
    // hasn't loaded yet, or we're not actually logged in
    if (!firstLoadPaused) {
      setActiveRole("");
    }

    return;
  }, [firstLoadPaused, loginContext, activeRole]);

  return (
    <div className="contentContainer">
      <LoginContext.Provider value={loginContext}>
        <MaybeRedirect
          singlePage={isAdmin && singlePage}
          loggedIn={loginContext.loggedIn}
          isLoaded={!firstLoadPaused}
        />

        <AdminToolbar
          singlePage={singlePage}
          setSinglePage={setSinglePage}
          activeRole={activeRole}
          setActiveRole={setActiveRole}
        />

        <FirebaseImage
          imageid="eeiLogo"
          alt="Education Excellence Initiative company logo"
          className="companyLogo"
          hide={firstLoadPaused}
        />

        <h1>Education Excellence Initiative</h1>

        <div className="mainContent">
          <Home shouldLoad={!firstLoadPaused} />

          <MainPage
            singlePage={singlePage}
            isLoading={firstLoadPaused}
            activeRole={activeRole}
          />

          <SignUp adminOnly={true} />
          <FulfillmentPolicy />
        </div>

        <ContactInfo loggedIn={loginContext.loggedIn} />
        <LegalDisclaimers />
      </LoginContext.Provider>
    </div>
  );
}


const Routed = createBrowserRouter([{
  path: "/*",
  element: <App />,
}]);


export { Routed };